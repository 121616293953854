import Module from "./Module.vue";
import Tool from "./utils/tools-emun";

const moduleRoute = {
  path: "/tools",
  component: Module,
  children: [
    {
      path: "/",
      name: "tools",
      component: () => import("./views/Home.vue"),
    },
    {
      path: `${Tool.BoxPlot.name}`,
      component: () =>
        import(
          /* webpackChunkName: "boxplot" */ "./views/visualization/descriptvePlots/BoxPlot.vue"
        ),
    },
    {
      path: `${Tool.DotPlot.name}`,
      component: () =>
        import(
          /* webpackChunkName: "dotplot" */ "./views/visualization/descriptvePlots/DotPlot.vue"
        ),
    },
    {
      path: `${Tool.PrincipalComponentAnalysis.name}`,
      component: () =>
        import(
          /* webpackChunkName: "pca" */ "./views/visualization/featureExplorationDimensionality/PrincipalComponentAnalysis.vue"
        ),
    },
    {
      path: `${Tool.tDistributedStochasticNeighborEmbedding.name}`,
      component: () =>
        import(
          /* webpackChunkName: "tsne" */ "./views/visualization/featureExplorationDimensionality/TSNE.vue"
        ),
    },
    {
      path: `${Tool.InstrumentPlacementMap.name}`,
      component: () =>
        import(
          /* webpackChunkName: "choropleth" */ "./views/visualization/maps/ChoroplethMap.vue"
        ),
    },
    {
      path: `${Tool.AptivaAnalysisXML.name}`,
      component: () =>
        import(
          /* webpackChunkName: "aptivaXML" */ "./views/development/adaptativaAnalysis/AptivaAnalysisXML.vue"
        ),
    },
    {
      path: `${Tool.AptivaLXBParser.name}`,
      component: () =>
        import(
          /* webpackChunkName: "aptivaXML" */ "./views/development/adaptativaAnalysis/AptivaLXBParser.vue"
        ),
    },
    {
      path: `${Tool.ROCcurves.name}`,
      name: "roc",
      component: () =>
        import(
          /* webpackChunkName: "roc" */ "./views/development/classificationAnalysis/ROCCurves.vue"
        ),
    },
    {
      path: `${Tool.MachineLearningClassification.name}`,
      component: () =>
        import(
          /* webpackChunkName: "machineLearningClassification" */ "./views/development/classificationAnalysis/MachineLearningClassification.vue"
        ),
    },
    {
      path: `${Tool.ViolinPlot.name}`,
      component: () =>
        import(
          /* webpackChunkName: "violinPlot" */ "./views/visualization/descriptvePlots/ViolinPlot.vue"
        ),
    },
    {
      path: `${Tool.DotPlotViolinPlot.name}`,
      component: () =>
        import(
          /* webpackChunkName: "violinDotCombinedPlot" */ "./views/visualization/descriptvePlots/ViolinDotCombinedPlot.vue"
        ),
    },
    {
      path: `${Tool.UpsetPlot.name}`,
      component: () =>
        import(
          /* webpackChunkName: "upsetPlot" */ "./views/visualization/insertionPlots/UpsetPlot.vue"
        ),
    },
    {
      path: `${Tool.RidgePlot.name}`,
      component: () =>
        import(
          /* webpackChunkName: "ridgePlot" */ "./views/visualization/descriptvePlots/RidgePlot.vue"
        ),
    },
    {
      path: `${Tool.VolcanoPlot.name}`,
      component: () =>
        import(
          /* webpackChunkName: "volcanoPlot" */ "./views/visualization/descriptvePlots/VolcanoPlot.vue"
        ),
    },
    {
      path: `${Tool.Correlogram.name}`,
      component: () =>
        import(
          /* webpackChunkName: "correlogramPlot" */ "./views/visualization/descriptvePlots/Correlogram.vue"
        ),
    },
    {
      path: `${Tool.MLLinearRegression.name}`,
      component: () =>
        import(
          /* webpackChunkName: "linearRegressionPlot" */ "./views/development/regressionAnalysis/LinearRegression.vue"
        ),
    },
    {
      path: `${Tool.SwarmPlot.name}`,
      component: () =>
        import(
          /* webpackChunkName: "swarmPlot" */ "./views/visualization/descriptvePlots/SwarmPlot.vue"
        ),
    },
    {
      path: `${Tool.HierarchicalClusteringDendrogram.name}`,
      component: () =>
        import(
          /* webpackChunkName: "dendrogram" */ "./views/visualization/featureExplorationDimensionality/Dendrogram.vue"
        ),
    },
    {
      path: `${Tool.BiomarkersEvaluation.name}`,
      component: () =>
        import(
          /* webpackChunkName: "biomarkersEval" */ "./views/visualization/scriptedWorkflows/BiomarkersEvaluation.vue"
        ),
    },
    {
      path: `${Tool.VennDiagram2.name}`,
      component: () =>
        import(
          /* webpackChunkName: "venn2" */ "./views/visualization/insertionPlots/Venn2.vue"
        ),
    },
    {
      path: `${Tool.VennDiagram3.name}`,
      component: () =>
        import(
          /* webpackChunkName: "venn3" */ "./views/visualization/insertionPlots/Venn3.vue"
        ),
    },
    {
      path: `${Tool.Heatmap.name}`,
      component: () =>
        import(
          /* webpackChunkName: "heatmap" */ "./views/visualization/featureExplorationDimensionality/Heatmap.vue"
        ),
    },
    {
      path: `${Tool.BivariateScatterPlot.name}`,
      component: () =>
        import(
          /* webpackChunkName: "bivariate-scatter" */ "./views/visualization/descriptvePlots/BivariateScatterPlot.vue"
        ),
    },
    {
      path: `${Tool.AptivaPrecisionAnalysis.name}`,
      component: () =>
        import(
          /* webpackChunkName: "AptivaPrecisionAnalysis" */ "./views/development/adaptativaAnalysis/AptivaPrecisionAnalysis.vue"
        ),
    },
    {
      path: `${Tool.AptivaWeeklyLogs.name}`,
      component: () =>
        import(
          /* webpackChunkName: "AptivaWeeklyLogs" */ "./views/development/adaptativaAnalysis/AptivaWeeklyLogs.vue"
        ),
    },
    {
      path: `${Tool.AptivaDatabase.name}`,
      component: () =>
        import(
          /* webpackChunkName: "AptivaWeeklyLogs" */ "./views/development/adaptativaAnalysis/AptivaDatabase.vue"
        ),
    },
    {
      path: `${Tool.AptivaImages.name}`,
      component: () =>
        import(
          /* webpackChunkName: "AptivaWeeklyLogs" */ "./views/development/adaptativaAnalysis/AptivaImages.vue"
        ),
    },
    {
      path: `${Tool.AptivaUsageReportConversion.name}`,
      component: () =>
        import(
          /* webpackChunkName: "AptivaUsageReport" */ "./views/business/commercialTools/AptivaUsageReport.vue"
        ),
    },
    {
      path: `${Tool.SampleSizeCaculator.name}`,
      component: () =>
        import(
          /* webpackChunkName: "SampleSizeCaculator" */ "./views/development/designOfExperiment/SampleSizeCalculator.vue"
        ),
    },
    {
      path: `${Tool.DiscriminationBetweenGroups.name}`,
      component: () =>
        import(
          /* webpackChunkName: "DiscriminationBetweenGroups" */ "./views/visualization/scriptedWorkflows/DiscriminationBetweenGroups.vue"
        ),
    },
    {
      path: `${Tool.PostTestProbabilities.name}`,
      component: () =>
        import(
          /* webpackChunkName: "PostTestProbabilities" */ "./views/visualization/descriptvePlots/PostTestProbabilities.vue"
        ),
    },
    {
      path: `${Tool.SankeyPlot.name}`,
      component: () =>
        import(
          /* webpackChunkName: "PostTestProbabilities" */ "./views/visualization/descriptvePlots/SankeyPlot/index.vue"
        ),
    },
    {
      path: `${Tool.DataExploration.name}`,
      component: () =>
        import(
          /* webpackChunkName: "DataExploration" */ "./views/development/designOfExperiment/DataExploration.vue"
        ),
    },
    {
      path: `${Tool.QUANTAFlashAcceleratedStability.name}`,
      component: () =>
        import(
          /* webpackChunkName: "QUANTAFlashAcceleratedStability" */ "./views/development/regressionAnalysis/AcceleratedStability.vue"
        ),
    },
    {
      path: `${Tool.QUANTAFlashOnboardStability.name}`,
      component: () =>
        import(
          /* webpackChunkName: "QUANTAFlashOnboardStability" */ "./views/development/regressionAnalysis/OnboardStability.vue"
        ),
    },
    {
      path: `${Tool.MasterCurve.name}`,
      component: () =>
        import(
          /* webpackChunkName: "QUANTAFlashOnboardStability" */ "./views/prototypes/MasterCurve.vue"
        ),
    },
    {
      path: `${Tool.XMLParser.name}`,
      component: () =>
        import(
          /* webpackChunkName: "QUANTAFlashOnboardStability" */ "./views/prototypes/XmlParser.vue"
        ),
    },
    {
      path: `${Tool.RadarPlot.name}`,
      component: () =>
        import(
          /* webpackChunkName: "QUANTAFlashOnboardStability" */ "./views/prototypes/RadarPlot.vue"
        ),
    },
  ],
};

export default (router) => {
  router.addRoute(moduleRoute);
};
